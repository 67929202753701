import { useEffect, useRef, useState } from "react";
import { IConnection } from "../../../models";
import { AISchemaOption, AITableOption } from "./AIWizard";
import { IOpenAIRequest } from "../../../models/OpenAI/IOpenAIRequest";
import { debounce } from "lodash";
import useTokenCalculator from "./useTokenCalculator";
import { IAzureOpenAIQueryResult } from "../../../models/OpenAI/IAzureOpenAIQueryResult";
import { mockEmptyAIResponse } from "./AIWizardFlyout.mock";
import { useMutation } from "@tanstack/react-query";
import { getConnections } from "../../connections/connectionList/getConnections";
import { getBillingUsage } from "../../users/api/getBillingUsage";
import { useAppDispatch } from "../../../redux/hooks";
import { addUsage } from "../../../redux/actions";
import { getMetadataTokenCount } from "src/api/OpenAI/getMetadataTokenCount";
import { getTables } from "src/api/metadata/getTables";
import { getSchemas } from "src/api/metadata/getSchemas";
import { generateQuery } from "src/api/OpenAI/generateQuery";
import { getColumnsForTable } from "src/api/metadata/getColumnsForTable";

export type OpenAITableMetadata = {
  table: AITableOption;
  metadata: string;
};

export const useAIWizardFunctions = () => {
  const dispatch = useAppDispatch();

  const [connectionList, setConnectionList] = useState<IConnection[]>([]);
  const [selectedConnections, setSelectedConnections] = useState<IConnection[]>(
    [],
  );
  const [processingConnections, setProcessingConnections] = useState(false);

  const [schemaList, setSchemaList] = useState<AISchemaOption[]>([]);
  const [selectedSchemas, setSelectedSchemas] = useState<AISchemaOption[]>([]);
  const [processingSchemas, setProcessingSchemas] = useState(false);

  const [tableList, setTableList] = useState<AITableOption[]>([]);
  const [selectedTables, setSelectedTables] = useState<AITableOption[]>([]);
  const [processingTables, setProcessingTables] = useState(false);

  const [aiResponse, setAiResponse] = useState<
    IAzureOpenAIQueryResult | undefined
  >(mockEmptyAIResponse);
  const [hasExecuted, setHasExecuted] = useState<boolean>(false);
  const [metadataTokenLength, setMetadataTokenLength] = useState<number>(0);
  const [tableMetadata, setTableMetadata] = useState<OpenAITableMetadata[]>([]);
  const [processingSubmit, setProcessingSubmit] = useState<boolean>(false);
  const [promptText, setPromptText] = useState<string>("");

  const { tokenCount, calculateTokens } = useTokenCalculator();

  const { mutate: getConnectionList } = useMutation({
    mutationKey: ["/account/connections"],
    mutationFn: (currentUserId: string) =>
      getConnections({ IsAdmin: false, CurrentUserId: currentUserId }),
    meta: {
      errorMessage: "Failed to get connection list due to the following error:",
    },
    onSuccess: (data) => {
      setConnectionList(data?.connections ?? []);
    },
  });

  const { mutateAsync: getUsageAsync } = useMutation({
    mutationKey: ["/billing/usage"],
    mutationFn: getBillingUsage,
    meta: {
      errorMessage: "Failed to get account usage due to the following error:",
    },
    onSuccess: (data) => {
      dispatch(addUsage(data));
    },
  });

  const { mutateAsync: getMetadataTokenCountAsync } = useMutation({
    mutationKey: ["/openai/tokens"],
    mutationFn: () => getMetadataTokenCount(tableMetadata),
    meta: {
      errorMessage: "Failed to get token count due to the following error: ",
    },
    onSuccess: (data) => {
      setMetadataTokenLength(data ?? 0);
    },
  });

  const { mutateAsync: getSchemasAsync } = useMutation({
    mutationKey: [
      `/schemas?catalogName=${encodeURIComponent(selectedConnections[0]?.name ?? "")}`,
    ],
    mutationFn: (connection: IConnection) =>
      getSchemas({ connection: connection.name! }),
    meta: {
      errorMessage: "Failed to get schemas due to the following error: ",
    },
    onSuccess: (data, variables) => {
      if ("error" in data) {
        throw new Error(data.error!.message);
      } else if ("results" in data && data.results) {
        const results = data.results[0].rows;
        const newSchemaOptions: AISchemaOption[] = results
          ? results.map((result: any) => {
              return {
                connectionName: result[0],
                driver: variables.driver!,
                schemaName: result[1],
              };
            })
          : [];

        setSchemaList((prevSchemaList) => [
          ...prevSchemaList,
          ...newSchemaOptions,
        ]);
      }
    },
  });

  const { mutateAsync: getTablesAsync } = useMutation({
    mutationKey: [
      `/tables?catalogName=${encodeURIComponent(selectedSchemas[0]?.connectionName)}&schemaName=${encodeURIComponent(selectedSchemas[0]?.schemaName)}`,
    ],
    mutationFn: (schemaOption: AISchemaOption) =>
      getTables({
        connection: schemaOption.connectionName,
        schema: schemaOption.schemaName,
      }),
    meta: {
      errorMessage: "Failed to get tables due to the following error: ",
    },
    onSuccess: (data, variables) => {
      // PR Comment: Is this not already caught by useMutation?
      if ("error" in data) {
        throw new Error(data.error!.message);
      } else if ("results" in data && data.results) {
        const results = data.results[0].rows;
        const newTableOptions: AITableOption[] = results
          ? results.map((result: any) => {
              return {
                connectionName: result[0],
                driver: variables.driver,
                schemaName: result[1],
                tableName: result[2],
              };
            })
          : [];

        setTableList((prevTableList) => [...prevTableList, ...newTableOptions]);
      }
    },
  });

  const { mutateAsync: getColumnMetadataAsync } = useMutation({
    mutationKey: [`/openai/query`],
    mutationFn: (table: AITableOption) =>
      getColumnsForTable({
        connectionName: table.connectionName,
        schema: table.schemaName,
        tableName: table.tableName,
        headers: { "Connect-Cloud-Client": "CDataDataExplorer" },
      }),
    meta: {
      errorMessage: "Failed to to get columns due to the following error:  ",
    },
    onSuccess: (data, variables) => {
      let tableString = `[${variables.connectionName}].[${variables.schemaName}].[${variables.tableName}](`;
      data.forEach((column) => (tableString += `${column.columnName}, `));

      tableString = tableString.slice(0, -2); // Lazy way to get rid of the last comma and space
      tableString += ")";

      const newMetadataObject: OpenAITableMetadata = {
        table: variables,
        metadata: tableString,
      };
      setTableMetadata([...tableMetadata, newMetadataObject]);
    },
  });

  const { mutateAsync: sendQueryGenerationRequestAsync } = useMutation({
    mutationKey: [`/openai/query`],
    mutationFn: generateQuery,
    meta: {
      errorMessage: "Failed to to get response from service:  ",
    },
    onSuccess: async (data) => {
      setAiResponse(data);
      await getUsageAsync();
    },
  });

  // Whenever the metadata from the selected tables updates, we need to update the token count automatically.
  useEffect(() => {
    if (selectedTables?.length > 0) {
      getMetadataTokenCountAsync();
    } else {
      setMetadataTokenLength(0);
    }
  }, [tableMetadata]); // eslint-disable-line

  // In the event that the user has only selected one connection, and that connection only has one schema,
  // we auto-select that schema for them. It is not deselected if another connection is added.
  useEffect(() => {
    async function fetchSingleConnectionSingleSchemaData() {
      await onSchemaChange(schemaList);
    }

    if (selectedConnections?.length === 1 && schemaList?.length === 1) {
      fetchSingleConnectionSingleSchemaData();
    }
  }, [selectedConnections, schemaList]); // eslint-disable-line

  async function onConnectionChange(newConnections: IConnection[]) {
    setProcessingConnections(true);

    // If a connection was removed, remove the associated schemas, tables, and metadata info.
    const removedConnections = selectedConnections.filter(
      (conn) => !newConnections.includes(conn),
    );
    if (removedConnections.length > 0) {
      removeSchemasForConnection(removedConnections[0]);
    }

    // If a connection was added, fetch the schemas associated with that connection.
    const addedConnections = newConnections.filter(
      (conn) => !selectedConnections.includes(conn),
    );
    if (addedConnections.length > 0) {
      await getSchemasAsync(addedConnections[0]);
    }

    setSelectedConnections(newConnections);
    setProcessingConnections(false);
    setHasExecuted(false);
  }

  async function onSchemaChange(newSchemas: AISchemaOption[]) {
    setProcessingSchemas(true);

    // If a schema was removed, remove any associated tables.
    const removedSchemas = selectedSchemas.filter(
      (schema) => !newSchemas.includes(schema),
    );
    if (removedSchemas.length > 0) {
      removeTablesForSchemas(removedSchemas);
    }

    // If a schema was added, expand the list of available tables.
    const addedSchemas = newSchemas.filter(
      (schema) => !selectedSchemas.includes(schema),
    );
    if (addedSchemas.length > 0) {
      await getTablesAsync(addedSchemas[0]);
    }

    setSelectedSchemas(newSchemas);
    setProcessingSchemas(false);
    setHasExecuted(false);
  }

  async function onTableChange(newTables: AITableOption[]) {
    setProcessingTables(true);

    // Check if a table was removed. If it was, remove it from the metadata string array.
    const removedTables = selectedTables.filter(
      (table) => !newTables.includes(table),
    );
    if (removedTables.length > 0) {
      removeMetadataForTables(removedTables);
    }

    // Check if a table was added. If it was, add it to the metadata string array.
    const addedTables = newTables.filter(
      (table) => !selectedTables.includes(table),
    );
    if (addedTables.length > 0) {
      await getColumnMetadataAsync(addedTables[0]);
    }

    setSelectedTables(newTables);
    setProcessingTables(false);
    setHasExecuted(false);
  }

  function removeSchemasForConnection(connection: IConnection) {
    // Get the list of schemas that should be removed
    const removedSchemas = schemaList.filter(
      (schema) => schema.connectionName === connection.name,
    );

    // Filter out schema options & seleced schemas that belong to the removed connection
    const trimmedSchemaList = schemaList.filter(
      (schema) => !removedSchemas.includes(schema),
    );
    const trimmedSelectedSchemas = selectedSchemas.filter(
      (schema) => !removedSchemas.includes(schema),
    );

    // Remove tables for each of the purged schemas
    removeTablesForSchemas(removedSchemas);
    setSchemaList(trimmedSchemaList);
    setSelectedSchemas(trimmedSelectedSchemas);
  }

  function removeTablesForSchemas(schemas: AISchemaOption[]) {
    // Get the full list of tables that need to be removed for each passed in schema
    let tablesToRemove: AITableOption[] = [];
    schemas.forEach((schema) => {
      const tables = tableList.filter(
        (table) => table.schemaName === schema.schemaName,
      );
      tablesToRemove = [...tablesToRemove, ...tables];
    });

    // Remove each entry in tablesToRemove from both TableList and SelectedTables
    const trimmedTableList = tableList.filter(
      (table) => !tablesToRemove.includes(table),
    );
    const trimmedSelectedTables = selectedTables.filter(
      (table) => !tablesToRemove.includes(table),
    );

    // Remove metadata entries for each of the purged tables
    removeMetadataForTables(tablesToRemove);
    setTableList(trimmedTableList);
    setSelectedTables(trimmedSelectedTables);
  }

  function removeMetadataForTables(tables: AITableOption[]) {
    // For each table passed in, get the tableMetadata associated with that table
    let metadataToRemove: OpenAITableMetadata[] = [];
    tables.forEach((table) => {
      const metadata = tableMetadata.filter(
        (metadata) => metadata.table === table,
      );
      metadataToRemove = [...metadataToRemove, ...metadata];
    });

    // Remove the matching entries in tableMetadata
    const trimmedMetadata = tableMetadata.filter(
      (metadata) => !metadataToRemove.includes(metadata),
    );
    setTableMetadata(trimmedMetadata);
  }

  function onPromptChange(prompt: string) {
    setPromptText(prompt);
    debouncedCalculateTokens(prompt);
    setHasExecuted(false);
  }

  async function sendQueryGenerationRequest() {
    setProcessingSubmit(true);

    const trimmedPrompt = promptText.trim();
    setPromptText(trimmedPrompt);
    debouncedCalculateTokens?.cancel();
    calculateTokens(trimmedPrompt);

    const metadata = tableMetadata.map(
      (tableMetadata) => tableMetadata.metadata,
    );
    const data: IOpenAIRequest = {
      promptText: trimmedPrompt,
      metadata: metadata,
    };

    await sendQueryGenerationRequestAsync(data);

    setProcessingSubmit(false);
    setHasExecuted(true);
  }

  function resetFields() {
    setSelectedConnections([]);
    setSelectedSchemas([]);
    setSelectedTables([]);
    setSchemaList([]);
    setTableList([]);
    setPromptText("");
    calculateTokens("");
    setMetadataTokenLength(0);
    setTableMetadata([]);
    setAiResponse(undefined);
  }

  const debouncedCalculateTokens = useRef(
    debounce(calculateTokens, 500),
  ).current;

  return {
    aiResponse,
    connectionList,
    getConnectionList,
    getUsageAsync,
    hasExecuted,
    metadataTokenLength,
    onConnectionChange,
    onPromptChange,
    onSchemaChange,
    onTableChange,
    processingConnections,
    processingSchemas,
    processingTables,
    processingSubmit,
    promptText,
    resetFields,
    schemaList,
    selectedConnections,
    selectedSchemas,
    selectedTables,
    sendQueryGenerationRequest,
    tableList,
    tokenCount,
  };
};

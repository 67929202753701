import { cdataFetch } from "../cdataFetch";
import { IOpenAIRequest } from "src/models/OpenAI/IOpenAIRequest";
import { OpenAITableMetadata } from "src/pages/dataExplorer/AIWizardFlyout/useAIWizardFunctions";

export function getMetadataTokenCount(metadataStrings: OpenAITableMetadata[]) {
  const metadata = metadataStrings.map(
    (tableMetadata) => tableMetadata.metadata,
  );
  const data: IOpenAIRequest = {
    promptText: "",
    metadata: metadata,
  };

  return cdataFetch<number>({
    method: "POST",
    url: "/openai/tokens",
    body: data,
  });
}

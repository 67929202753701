import { IAzureOpenAIQueryResult } from "src/models/OpenAI/IAzureOpenAIQueryResult";
import { cdataFetch } from "../cdataFetch";
import { IOpenAIRequest } from "src/models/OpenAI/IOpenAIRequest";

export function generateQuery(body: IOpenAIRequest) {
  return cdataFetch<IAzureOpenAIQueryResult>({
    method: "POST",
    url: "/openai/query",
    body: body,
  });
}

import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, Chip, ThemeProvider } from "@mui/material";
import Loader from "../Loader";
import { createAutocompleteTheme } from "@cdatacloud/component-library";
import "./CDataAutocompleteMulti.scss";

interface ICDataAutocompleteMulti<T> {
  /** Any number of chips over this limit will display as a chip that says "+x more". If not provided, the field will grow to display all chips. */
  chipDisplayLimit?: number;
  /** The text that appears in the selection chips. */
  chipLabel: (option: T) => string;
  /** Sets the entire selector and all options to disabled. */
  disableWholeSelector: boolean;
  /** The appearance of the items when the dropdown menu is open. This component automatically adds checkboxes, so they should not be included here. */
  dropdownLabel: (option: T) => JSX.Element;
  /** Optional function to determine whether an individual option in the list should be disabled. See ConnectionSelector.tsx for an example. */
  getOptionDisabled?: (option: T) => boolean;
  /** For some reason nobody understands, Autocomplete uses this field for option keys. As a result, excluding it floods the console with errors. This function
   * must be configured to return a unique value for each option to act as the key. See https://github.com/mui/material-ui/issues/31192 for more information. */
  getOptionLabel: (option: T) => string;
  /** The handler function for when data is selected or deselected. Autocomplete sends the full array of selected data when this is called. */
  handleChange: (event: any, options: T[]) => void;
  /** The id to assign to the autocomplete element. */
  id: string;
  /** If true, shows the field in an error state. If false or not provided, no error state is shown. */
  isInvalid?: boolean;
  /** If provided, displays a spinner over the input portion of the field when true. */
  isLoading?: boolean;
  /** The full list of options to display in the selector. They must all be of the same type. */
  options: T[];
  /** The controlled state variable for tracking which options have been selected. */
  selectedValues: T[];
}

export function CDataAutocompleteMulti<T>(props: ICDataAutocompleteMulti<T>) {
  const {
    chipDisplayLimit,
    chipLabel,
    disableWholeSelector,
    dropdownLabel,
    getOptionDisabled,
    getOptionLabel,
    handleChange,
    id,
    isInvalid,
    isLoading,
    options,
    selectedValues,
  } = props;

  const checkedIcon = (
    <i className="fa-solid fa-square-check autocomplete-filled-checkbox"></i>
  );
  const emptyIcon = (
    <i className="fa-regular fa-square autocomplete-empty-checkbox"></i>
  );

  return (
    <div className="container-cdata-autocomplete container-cdata-autocomplete-multi">
      <ThemeProvider theme={createAutocompleteTheme(isInvalid ?? false)}>
        <Autocomplete
          componentsProps={{
            // The paper classname is necessary for styling the dropdown menu when portaling is enabled.
            // Portaling needs to be enabled, or else this component won't work inside non-MUI modals.
            paper: {
              className: "cdata-autocomplete-multi-select-menu",
            },
            popupIndicator: {
              disableRipple: true,
            },
          }}
          multiple
          id={id}
          data-testid={id}
          options={options}
          noOptionsText="No matching options"
          disableCloseOnSelect
          disableClearable // Prevents the X to clear all from appearing
          disabled={disableWholeSelector}
          getLimitTagsText={(number) => <Chip label={`+${number} more`} />}
          getOptionDisabled={(option) =>
            (isLoading || getOptionDisabled?.(option)) ?? false
          }
          getOptionLabel={getOptionLabel}
          limitTags={chipDisplayLimit}
          loading={isLoading}
          loadingText={
            <Box fontSize={14}>
              <Loader customHeightClass="h-100" />
            </Box>
          }
          renderOption={(props, option, { selected }) => {
            const { key, ...optionProps } = props;
            return (
              <li key={key} {...optionProps}>
                <Checkbox
                  className="px-0 me-2"
                  disableRipple
                  icon={emptyIcon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {dropdownLabel(option)}
              </li>
            );
          }}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => {
              const { key, ...tagProps } = getTagProps({ index });
              return (
                <Chip
                  deleteIcon={
                    <i className="fa fa-times fa-solid remove-chip-icon align-content-center" />
                  }
                  key={key}
                  label={chipLabel(option)}
                  {...tagProps}
                />
              );
            })
          }
          onChange={handleChange}
          popupIcon={
            <i
              className={
                "dropdown-icon fa-regular fa-chevron-down me-1 ms-1 no-pointer-events"
              }
            />
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={selectedValues?.length > 0 ? "" : "Select"}
              InputLabelProps={{
                shrink: false,
              }}
              size="small"
            />
          )}
          value={selectedValues}
        />
      </ThemeProvider>
      {isLoading && (
        <Box position="absolute" top={0} left={0} right={0} bottom={0}>
          <Loader customHeightClass="h-100" />
        </Box>
      )}
    </div>
  );
}

import { useEffect } from "react";
import { Row, Col, Spinner, Badge } from "reactstrap";
import { InfoIcon } from "../../../components/InfoIcon";
import { FeatureId, UserRole } from "../../../models";
import { ConnectionSelector } from "./ConnectionSelector";
import { TableSelector } from "./TableSelector";
import { PromptInput } from "./PromptInput";
import { ResponseField } from "./ResponseField";
import {
  BILLING_TAB_DESTINATION,
  UPDATE_PLAN,
} from "../../../components/notification/DashboardNotificationManagerConstants";
import { getSalesEmailAddress } from "../../../utility/LocalizedEmailAddresses";
import { SchemaSelector } from "./SchemaSelector";
import { useAIWizardFunctions } from "./useAIWizardFunctions";
import {
  IconOverrideOptions,
  NotificationBar,
} from "../../../components/notification/NotificationBar";
import { CDataTypography } from "../../../components/text/CDataTypography";
import { useAppSelector } from "../../../redux/hooks";

export type AISchemaOption = {
  connectionName: string;
  driver: string;
  schemaName: string;
};

export type AITableOption = {
  connectionName: string;
  driver: string;
  schemaName: string;
  tableName: string;
};

export const AIWizard = (props: {
  isVisible: boolean;
  closeWizard: () => void;
}) => {
  const currentUser = useAppSelector((state) => state.user);
  const subscription = useAppSelector((state) => state.subscription);
  const usage = useAppSelector((state) => state.usage);

  const aiFunctions = useAIWizardFunctions();
  const {
    aiResponse,
    connectionList,
    getConnectionList,
    getUsageAsync,
    hasExecuted,
    metadataTokenLength,
    onConnectionChange,
    onPromptChange,
    onSchemaChange,
    onTableChange,
    processingConnections,
    processingSchemas,
    processingSubmit,
    processingTables,
    promptText,
    resetFields,
    schemaList,
    selectedConnections,
    selectedSchemas,
    selectedTables,
    sendQueryGenerationRequest,
    tableList,
    tokenCount,
  } = aiFunctions;

  const aiCreditsOverLimit = usage.aiCreditsOverLimit ?? false;
  const planIncludesFeature =
    subscription.limits?.availableFeatureIds?.includes(FeatureId.AIGenerator) ??
    false;
  const tokensOverLimit = tokenCount + metadataTokenLength > 2000;

  useEffect(() => {
    async function asyncAPI() {
      await getConnectionList(currentUser.id);
      await getUsageAsync();
    }
    asyncAPI();
  }, []); // eslint-disable-line

  const inputCanBeExecuted =
    selectedConnections?.length > 0 &&
    selectedSchemas?.length > 0 &&
    selectedTables?.length > 0 &&
    promptText !== "" &&
    !hasExecuted;

  function renderCurrentCredits() {
    if (subscription.limits!.aiCreditLimit !== -1) {
      return `${subscription.limits!.aiCreditLimit - usage.aiCreditsCount}/${subscription.limits!.aiCreditLimit}`;
    } else {
      return "Unlimited";
    }
  }

  function isFeatureDisabled() {
    return aiCreditsOverLimit || !planIncludesFeature;
  }

  const isUserRoleAdmin = currentUser.role === UserRole.Admin;
  const infoIconText =
    "The quantity of AI generated prompts you can initiate per month. Contact Sales to upgrade your plan to unlock more credits.";

  return (
    <>
      <div className="p-3" data-testid="section-body">
        {planIncludesFeature ? (
          <Row className="mb-2">
            <Col>
              <div className="d-flex align-items-center">
                <h5 className="mb-0 credit-label">Monthly Credits:</h5>
                <InfoIcon
                  tooltipMessage={infoIconText}
                  className="info-icon-root ms-2"
                  iconId="credit-info"
                />
                <Badge className="align-middle ms-2 p-2">
                  {renderCurrentCredits()}
                </Badge>
              </div>
            </Col>
          </Row>
        ) : null}
        {aiCreditsOverLimit ? (
          <div className="warning-bar mb-3" data-testid="warning-bar">
            <NotificationBar
              barColor={"notification-bar-red"}
              message={
                "Your credit usage has reached the limit for this billing cycle."
              }
              linkText={isUserRoleAdmin ? UPDATE_PLAN : undefined}
              navigateDestination={BILLING_TAB_DESTINATION}
            />
          </div>
        ) : null}
        {!planIncludesFeature ? (
          <div className="warning-bar mb-3">
            <NotificationBar
              barColor={"notification-bar-blue"}
              hideTextOverflow={false}
              message={
                <CDataTypography color="typography-color-white">
                  This feature is not available with your current plan. To
                  upgrade your plan, please contact{" "}
                  <a href={`mailto:${getSalesEmailAddress()}`}>
                    {getSalesEmailAddress()}
                  </a>{" "}
                  for further assistance.
                </CDataTypography>
              }
              iconOverride={IconOverrideOptions.Triangle}
            />
          </div>
        ) : null}
        <div>
          Welcome to CData Connect Cloud’s AI Generator! Below is an example of
          the kind of prompt you can run to generate a custom SQL Statement.
        </div>
        {processingConnections || processingSchemas || processingTables ? (
          <div>
            <div className="loading-background" />
          </div>
        ) : null}
        {processingSubmit ? (
          <div>
            <div className="loading-background" />
            <Spinner className="spinner-border loading-spinner" color="info" />
          </div>
        ) : null}
        {connectionList ? (
          <>
            <ConnectionSelector
              connectionList={connectionList}
              disabled={isFeatureDisabled()}
              onConnectionChange={onConnectionChange}
              processingRequest={processingConnections}
              selectedConnections={selectedConnections}
            />
            <SchemaSelector
              disabled={
                isFeatureDisabled() ||
                selectedConnections.length === 0 ||
                schemaList.length <= 1
              }
              onSchemaChange={onSchemaChange}
              processingRequest={processingSchemas}
              schemaList={schemaList}
              selectedSchemas={selectedSchemas}
              tokensOverLimit={tokensOverLimit}
            />
            <TableSelector
              disabled={isFeatureDisabled() || selectedSchemas.length === 0}
              onTableChange={onTableChange}
              processingRequest={processingTables}
              selectedTables={selectedTables}
              tableList={tableList}
              tokensOverLimit={tokensOverLimit}
            />
            <PromptInput
              aiResponse={aiResponse}
              inputCanBeExecuted={inputCanBeExecuted}
              onPromptChange={onPromptChange}
              promptText={promptText}
              sendQueryGenerationRequest={() => sendQueryGenerationRequest()}
              promptDisabled={
                isFeatureDisabled() || selectedTables.length === 0
              }
              resetFields={resetFields}
              tokenCount={tokenCount + metadataTokenLength}
              resetDisabled={isFeatureDisabled()}
              tokensOverLimit={tokensOverLimit}
            />
            <ResponseField
              aiResponse={aiResponse}
              closeWizard={props.closeWizard}
              responseControlsDisabled={aiCreditsOverLimit && !hasExecuted}
            />
          </>
        ) : (
          <div>
            Error loading connections. Please ensure that you have at least one
            connection on your account.
          </div>
        )}
      </div>
    </>
  );
};
